import React, { useState, useEffect } from "react";
import {
    faLocationDot,
    faCar,
    faHeart as faHeartSolid,
} from "@fortawesome/free-solid-svg-icons";
import { faHeart as faHeartRegular } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./styles.css";


function mode(array) {
    if (array.length === 0)
        return null;
    var modeMap = {};
    var maxEl = array[0], maxCount = 1;
    for (var i = 0; i < array.length; i++) {
        var el = array[i];
        if (modeMap[el] == null)
            modeMap[el] = 1;
        else
            modeMap[el]++;
        if (modeMap[el] > maxCount) {
            maxEl = el;
            maxCount = modeMap[el];
        }
    }
    return maxEl;
}

const DailyLunchView = ({ menu, index, onClick }) => {
    const modePrice = mode(menu.meals.map(x => x.price)) ?? menu?.price
    const [isLiked, setIsLiked] = useState(false);

    // Check if the restaurant is already liked when the component loads
    useEffect(() => {
        const likedRestaurants = JSON.parse(localStorage.getItem("likedRestaurants")) || [];
        if (likedRestaurants.includes(menu.restaurant.id)) {
            setIsLiked(true);  // Set as liked if the restaurant ID is found in localStorage
        }
    }, [menu.restaurant.id]);

    const LikeRestaurant = (e, restaurantId) => {
        e.stopPropagation();  // Prevents the outer onClick from firing

        // Toggle the liked status
        const likedRestaurants = JSON.parse(localStorage.getItem("likedRestaurants")) || [];

        if (likedRestaurants.includes(restaurantId)) {
            // If it's already liked, remove it from the list
            const updatedLikedRestaurants = likedRestaurants.filter(id => id !== restaurantId);
            localStorage.setItem("likedRestaurants", JSON.stringify(updatedLikedRestaurants));
            setIsLiked(false);
        } else {
            // If not liked, add it to the list
            likedRestaurants.push(restaurantId);
            localStorage.setItem("likedRestaurants", JSON.stringify(likedRestaurants));
            setIsLiked(true);
        }
    };

    return (
        <div
            key={menu.id}
            data-index={index}
            className={`menu-item cards-container mb-4 start-animation`}
            style={{ animationDelay: `${index * 0.3}s` }}
        >
            <div className="cards w-100 lunchcard-daily" onClick={onClick}>
                <div className="row card-row m-0">
                    <div className="col-md-3 lunch-card-img bg-lunchdark d-flex justify-content-center flex-column">
                        <div className="lunch-card-image-div p-2 d-none d-md-block">
                            <img
                                src={`images/${menu.restaurant.logo}`}
                                alt="Logo"
                                loading="lazy"
                            />
                        </div>
                    </div>
                    <div className="col card-description-body">
                        <div
                            className="card-restaurant-name-header p-1 bg-lunchdark text-white fs-5 font-weight-bold d-flex justify-content-between"
                            onClick={(e) => e.stopPropagation()}
                        >
                            {menu.restaurant.name}
                            <span
                                className={`text-right like-button ${isLiked ? "liked" : ""}`}
                                onClick={(e) => LikeRestaurant(e, menu.restaurant.id)}
                            >
                                {isLiked ? (
                                    <FontAwesomeIcon icon={faHeartSolid} className="me-1" />
                                ) : (
                                    <FontAwesomeIcon icon={faHeartRegular} className="me-1" />
                                )}
                            </span>
                        </div>
                        <ul className="cards-body p-0 rounded-0 list-group border-md-none">
                            {menu.meals.map((lunch, index) => (
                                <div key={index} className="cards-text">
                                    <div className="row">
                                        <div className="col">
                                            <li className="list-group-item cards-text d-flex justify-content-between">
                                                <span>{lunch.description}</span>
                                                {lunch.price !== modePrice && (<span className="cards-text text-nowrap"> {lunch.price} kr</span>)}
                                            </li>
                                        </div>

                                    </div>
                                </div>
                            ))}
                            <div className="cards-text px-3 py-2 mb-0">
                                <div className="row">
                                    <div className="col">
                                        <p className="cards-text mb-0">
                                            <small className="text-muted">
                                                {menu.restaurant.standing_message}
                                            </small>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </ul>
                    </div>
                </div>
                <div className="row cards-footer m-0 bg-lunchdark text-white">
                    <div className="row">
                        <div className="col col-4">
                            <div className="row">
                                <div className="col">
                                    <div className="card-footer-text overflow-x-hidden text-nowrap text-center">
                                        <FontAwesomeIcon icon={faLocationDot} className="me-1" />
                                        {`${menu.restaurant.address || ""} ${menu.restaurant.house_number} - ${menu.restaurant.county}`}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col col-4">
                            <div className="row">
                                <div className="col">
                                    <div className="card-footer-text overflow-x-hidden text-nowrap text-center">
                                        <FontAwesomeIcon icon={faCar} className="me-1" />{" "}
                                        {menu.distance}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col col-4">
                            <div className="row">
                                <div className="col">
                                    <div className="card-footer-text overflow-x-hidden text-nowrap text-center">
                                        <b>kr</b> {modePrice}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DailyLunchView;
