export const searchOptions  = [
    { value: 'vegetariskt', label: 'Vegetariskt'},
    { value: 'husmanskost', label: 'Husmanskost'},
    { value: 'pasta', label: 'Pasta'},
    { value: 'kött', label: 'Kött'},
    { value: 'fisk', label: 'Fisk'},
    { value: 'pizza', label: 'Pizza'},
    { value: 'veganskt', label: 'Veganskt'},
    { value: 'kyckling', label: 'Kyckling'},
];

export const sortOptions  = [
    { value: 'distance_asc', label: 'Avstånd, stigande'},
    { value: 'distance_desc', label: 'Avstånd, fallande'},
    { value: 'price_asc', label: 'Pris, stigande'},
    { value: 'price_desc', label: 'Pris, fallande'},
    { value: 'name_asc', label: 'Namn, stigande'},
    { value: 'name_desc', label: 'Namn, fallande'},
];